import { createSelector } from 'reselect';
import _ from 'underscore';
import constants from '../../constants/Constants';
import FilterUtils from '../../utils/FilterUtils';
import selectedCheckboxes from './selectedCheckboxes';

export default createSelector(
    (state, ownProps) =>
        ownProps && ownProps.filterSelection
            ? ownProps.filterSelection.searchPhrase
            : state.calendar.freeTextSearchPhrase,
    (state, ownProps) =>
        Object.values(
            selectedCheckboxes(
                state,
                ownProps && ownProps.filterSelection
                    ? ownProps.filterSelection.selectedCheckboxes
                    : state.calendar.selectedCheckboxes
            )
        ),
    (state, ownProps) =>
        ownProps
            ? ownProps.filterSelection.selectedProducts
            : state.calendar.selectedProducts,
    (state, ownProps) =>
        ownProps
            ? ownProps.filterSelection.ncaaFilter
            : state.calendar.ncaaFilter,
    (searchPhrases, checkboxes, selectedProducts, ncaaFilter) => {
        if (
            checkboxes.length === 0 &&
            searchPhrases.length === 0 &&
            !FilterUtils.anyNcaaFilterSet(ncaaFilter) &&
            !selectedProducts.length
        ) {
            return ['There are no filters selected.'];
        }

        const prefixes = [
            'and',
            'and',
            'and',
            'and',
            'and',
            'and',
            'and',
            'that',
        ];

        const parts = getMatchesParts(checkboxes, selectedProducts);

        const addToParts = function (entries, description) {
            if (entries.length) {
                const entriesString = commaAnd(entries, 'or');
                parts.push(`${prefixes.pop()} ${description} ${entriesString}`);
            }
        };

        const addCheckboxesToParts = function (groupId, description) {
            return addToParts(getNames(checkboxes, groupId), description);
        };

        function addNcaaParts(ncaaFilter) {
            let states, plural, divisions;
            if (ncaaFilter.divisions && ncaaFilter.divisions.length) {
                divisions = _.pluck(ncaaFilter.divisions, 'value');
                plural = ncaaFilter.divisions > 1 ? ' is in one of ' : ' is ';
                return parts.push(
                    `and NCAA match division ${plural} ${divisions.join(
                        ' or '
                    )}`
                );
            } else if (
                ncaaFilter.venueStates &&
                ncaaFilter.venueStates.length
            ) {
                states = _.pluck(ncaaFilter.venueStates, 'label');
                plural = states.length > 1 ? ' is in one of ' : ' is ';
                return parts.push(
                    `and NCAA venue state ${plural} ${states.join(' or ')}`
                );
            } else if (ncaaFilter.teamStates && ncaaFilter.teamStates.length) {
                let states = _.pluck(ncaaFilter.teamStates, 'label');
                if (ncaaFilter.teamStatesOnly) {
                    plural = states.length > 1 ? ' are amongst ' : ' are in ';
                    return parts.push(
                        `and both NCAA team states ${plural} ${states.join(
                            ' or '
                        )}`
                    );
                }
                plural = states.length > 1 ? ' is in one of ' : ' is ';
                return parts.push(
                    `and NCAA team state ${plural} ${states.join(' or ')}`
                );
            }
        }

        addToParts(
            searchPhrases.map(s => `"${s}"`),
            'contain the phrase'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_COVERED_FROM,
            'are covered from'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_SEGMENT_TYPE,
            'package type is'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_COVERED_WITH,
            'are covered with'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_COVERAGE_PROVIDER,
            'with coverage provider:'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_AGE_GROUPS,
            'are within the age groups'
        );
        addCheckboxesToParts(
            constants.FILTER_GROUP_SPORT_TYPE,
            'are of sport type'
        );

        if (FilterUtils.anyNcaaFilterSet(ncaaFilter)) {
            // console.log(ncaaFilter);
            addNcaaParts(ncaaFilter);
        }

        const sports = getNames(checkboxes, constants.FILTER_GROUP_SPORTS);
        const sCount = sports.length;
        if (sCount === 0) {
            parts.push('for all sports');
        } else if (sCount <= 5) {
            parts.push(`for ${commaAnd(sports)}`);
        } else {
            parts.push(`for ${sCount} selected sports`);
        }

        return parts;
    }
);

function getMatchesParts(checkboxes, selectedProducts) {
    const matchParts = [];
    const availabilityNames = getNames(
        checkboxes,
        constants.FILTER_GROUP_MATCHES
    );

    if (!availabilityNames.length) {
        matchParts.push('All matches');
    } else {
        matchParts.push(commaAnd(availabilityNames, 'or') + ' Matches');
    }

    const productNames = {
        lo: 'Live Odds',
        ld: 'Live Data',
        bp: 'Betpal',
        lco: 'Live Channel Online',
        lct: 'Live Channel Trading',
        lcr: 'Live Channel Retail',
    };

    const productsPart = selectedProducts.length
        ? commaAnd(
              selectedProducts.map(product => productNames[product]),
              'or'
          )
        : 'all products';

    matchParts.push('for ' + productsPart);

    return matchParts;
}

function commaAnd(entries, glueWord = 'and') {
    entries = entries.filter(e => e !== '');
    if (!entries.length) {
        return '';
    }
    if (entries.length === 1) {
        return entries[0];
    }
    const last = entries.pop();
    return [entries.join(', '), glueWord, last].join(' ');
}

function getNames(checkboxes, groupId) {
    return FilterUtils.getInGroup(checkboxes, groupId).map(c => {
        return c.name.split(' ')[0];
    });
}
