import classnames from 'classnames';
import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { AV_PRODUCT } from '../../constants/audioVisual';
import views from '../../constants/views';
import { setFilters } from '../../stores/autobooking/actions';
import { addDialog } from '../../stores/dialog/actions';
import { dialogTypes } from '../../stores/dialog/constants';
import { closeContextMenu } from '../../stores/eventContextMenu/actions';
import { setDefaultUri } from '../../stores/inspector/actions';
import { setTab } from '../../stores/leftColumn/actions';
import { tabs } from '../../stores/leftColumn/constants';
import { unbookEvent } from '../../stores/shoppingBasket/actions';
import { setCurrentView } from '../../stores/viewport/actions';
import moment from '../../utils/CalendarMoment';
import Chat from '../../utils/Chat';
import Utils from '../../utils/Utils';
import DialogMask from '../dialog/DialogMask/DialogMask';
import FontIcon from '../icons/FontIcon/FontIcon';
import './EventContextMenu.scss';

const contextMenuItemSize = {
    width: 424,
    height: 33,
    headerHeight: 50,
    footerHeight: 60,
}; // if changed, also change in ./eventContextMenu.scss

const isMicrofrontend =
    window.location.href.includes('portal') ||
    window.location.href.includes('9090');

const calculatePosition = (cursorX, cursorY, contextMenuHeight) => {
    const margin = 10,
        contextMenuWidth = contextMenuItemSize.width,
        contextMenuRight = cursorX + contextMenuWidth,
        contextMenuBottom = cursorY + contextMenuHeight,
        viewPort = document.querySelector('.src-viewport'),
        viewPortPosition = {
            left: viewPort.offsetLeft,
            top: viewPort.offsetTop,
        },
        viewPortWidth = parseFloat(
            getComputedStyle(viewPort).width.replace('px', '')
        ),
        viewPortHeight = parseFloat(
            getComputedStyle(viewPort).height.replace('px', '')
        ),
        viewPortRight = viewPortPosition.left + viewPortWidth - margin,
        viewPortBottom = viewPortPosition.top + viewPortHeight - margin;

    let left = cursorX,
        top = cursorY;

    // out of bounds?
    if (contextMenuRight > viewPortRight) {
        left -= contextMenuWidth;
        // still out of bounds?
        if (left < viewPortPosition.left) {
            left = viewPortPosition.left + 10;
        }
    }

    // out of bounds?
    if (contextMenuBottom > viewPortBottom) {
        top -= contextMenuHeight;
        // still out of bounds?
        if (top < viewPortPosition.top) {
            top = viewPortPosition.top + 5;
        }
    }

    return {
        left,
        top,
    };
};

const LO_HISTORY = 802;
const LO_XML_LOGS = 803;
const SCOUT_XML_LOGS = 804;
const LO_VIEWER = 805;
const LO_CONFIG = 806;
const LIVE_CHAT = 808;
const INFO = 809;
const INFO_MASTER = 819;
const AUTOBOOKING = 810;
const CONTROL_ODDS = 811;
const LIVEDATA_CLIENT = 812;
const UF_ODSS_VIEWER = 813;
const LC_PREVIEW_PLAYER = 814;
const LCT_PLAYER = 817;
const LCT_PLAYER_SIMPLE = 818;
const UNBOOK_LO = 815;
const LC_INFO = 816;
const EXTRA_INFO = 820;
const UNBOOK_AV_LCO = 821;
const UNBOOK_AV_LCR = 822;
const UNBOOK_AV_LCT = 823;
const INSPECT = 824;
const MATCH_CONFIG = 825;

const DEVELOPMENT_LABEL = 'development';
const ADMIN_LABEL = 'admin';

class ContextMenu extends React.Component {
    constructor(props) {
        super(props);

        const cb = new Clipboard('.clipboardBtn');

        cb.on('success', function (e) {
            console.info(`Copied to clipboard: ${e.text}`);
        });
    }

    getItemsData() {
        const itemsData = [
            {
                code: LIVEDATA_CLIENT,
                text: 'Live Data Client',
                clickHandler: this.onLiveDataClientClick,
                icon: '',
            },
            {
                code: LO_VIEWER,
                text: 'Live Odds Viewer',
                clickHandler: this.onLiveoddsViewerClick,
                icon: '',
            },
            {
                code: MATCH_CONFIG,
                text: 'Match Config',
                clickHandler: this.onMatchConfigClick,
                icon: '',
            },
            {
                code: LO_CONFIG,
                text: 'Live Odds Config',
                clickHandler: this.onLiveoddsConfigClick,
                icon: '',
            },
            {
                code: LO_HISTORY,
                text: 'Live Odds History',
                clickHandler: this.onLiveoddsHistoryClick,
                icon: '',
            },
            {
                code: UF_ODSS_VIEWER,
                text: 'Unified Feed Odds History',
                clickHandler: this.onUFLiveoddsViewer,
                icon: '',
            },
            {
                code: SCOUT_XML_LOGS,
                text: 'Live Data XML Logs',
                clickHandler: this.onScoutXmlLogClick,
                icon: '',
            },
            {
                code: LO_XML_LOGS,
                text: 'Live Odds XML Logs',
                clickHandler: this.onLiveoddsXmlLogClick,
                icon: '',
            },
            {
                code: LIVE_CHAT,
                text: 'Live Chat',
                clickHandler: this.onLiveChatClick,
                icon: '',
            },
            {
                code: INFO,
                text: 'Event Info',
                clickHandler: this.onEventInfoClick,
                icon: '',
            },
            {
                code: EXTRA_INFO,
                text: 'Extended Event Info',
                clickHandler: this.onExtendedEventInfoClick,
                icon: '',
            },
            {
                code: LC_INFO,
                text: 'Live Channel Info',
                clickHandler: this.onLiveChannelInfoClick,
                icon: '',
            },
            // {
            //     code: INFO_MASTER,
            //     text: 'Master Event Information',
            //     clickHandler: this.onMasterInfoClick,
            //     icon: '', // TODO
            // },
            {
                code: LC_PREVIEW_PLAYER,
                text: 'Live Channel Preview',
                clickHandler: this.onLiveStreamClick,
                icon: '',
            },
            {
                code: LCT_PLAYER,
                text: 'Live Channel Trading Stream',
                clickHandler: this.openLctPlayer,
                icon: '',
            },
            {
                code: LCT_PLAYER_SIMPLE,
                text: 'Live Channel Trading Stream',
                clickHandler: this.openLctPlayerSimple,
                icon: '',
            },
            {
                code: AUTOBOOKING,
                text: 'Autobooking',
                clickHandler: this.onAutobookingclick,
                icon: '',
            },
            {
                code: CONTROL_ODDS,
                text: 'Control odds',
                clickHandler: this.onControlOddsClick,
                icon: '',
            },
            {
                code: UNBOOK_LO,
                text: 'Unbook Liveodds/Livedata',
                clickHandler: this.onUnbookLOClick,
                icon: '',
            },
            {
                code: UNBOOK_AV_LCO,
                text: 'Unbook Live Channel Online',
                clickHandler: this.onUnbookLCOClick,
                icon: '',
            },
            {
                code: UNBOOK_AV_LCR,
                text: 'Unbook Live Channel Retail',
                clickHandler: this.onUnbookLCRClick,
                icon: '',
            },
            {
                code: UNBOOK_AV_LCT,
                text: 'Unbook Live Channel Trading',
                clickHandler: this.onUnbookLCTClick,
                icon: '',
            },
            {
                code: INSPECT,
                text: 'Inspect',
                clickHandler: this.onInspectClick,
                icon: '',
                label: ADMIN_LABEL,
            },
            {
                code: MATCH_CONFIG,
                text: 'Live Odds Match Configuration',
                clickHandler: this.onMatchConfig,
                icon: '',
            },
            {
                text: 'Show event object...',
                clickHandler: this.onShowEventObjectClick,
                icon: '',
                label: DEVELOPMENT_LABEL,
            },
        ];

        // TODO: If we have master events, need to figure out a way to handle this

        // if (
        //     this.props.event.uri.includes('master') ||
        //     (this.props.event.parent &&
        //         this.props.event.parent.includes('master'))
        // ) {
        //     const uri = this.props.event.uri.includes('master')
        //         ? this.props.event.uri
        //         : this.props.event.parent;
        //     itemsData.push({
        //         text: `Master Event URI: ${uri} (click to copy)`,
        //         clickHandler: null,
        //         iconClass: null,
        //         classes: ['footer', 'matchIdCopier', 'clipboardBtn'],
        //         customProps: { 'data-clipboard-text': uri },
        //     });
        // }

        return itemsData;
    }

    onLiveDataClientClick = () => {
        const url = this.props.backendSettings.betradar.liveDataClient;
        Utils.openWindow(
            url.replace('{type}', 'match').replace('{id}', this.props.event.id),
            {},
            {
                directories: 'no',
                titlebar: 'no',
                toolbar: 'no',
                status: 'no',
                menubar: 'no',
                scrollbars: 'no',
                width: '455',
                height: '800',
            },
            '_blank'
        );
    };

    onLiveoddsViewerClick = () => {
        Utils.openWindow(
            this.props.backendSettings.betradar.loViewer.replace(
                '{uri}',
                this.props.event.uri
            )
        );
    };

    onUFLiveoddsViewer = () => {
        Utils.openWindow(this.props.backendSettings.betradar.systemMain, {
            page: 'uf_oddsHistory',
            eventId: this.props.event.uri,
            sportId: this.props.event.sportId,
            competitionName: this.props.event.ancestors
                ? `${this.props.event.ancestors[1].name} (${this.props.event.name})`
                : '',
            categoryName: this.props.event.realCategoryName,
        });
    };

    onLiveoddsConfigClick = () => {
        Utils.openWindow(
            this.props.backendSettings.betradar.systemMain,
            {
                page: 'liveodds_matchodds',
                matchid: this.props.event.id,
                sportId: this.props.event.sportId,
                realcategoryId: this.props.event.realCategoryId,
                tournamentId: this.props.event.tournamentId,
                dateofmatch: moment
                    .fromTimestamp(this.props.event.startDate)
                    .toFormat('yyyy-MM-dd T'),
                hometeam: this.props.event.teamHome.name,
                awayteam: this.props.event.teamAway.name,
            },
            {},
            'odds_configuration'
        );
    };

    onLiveoddsHistoryClick = () => {
        Utils.openWindow(this.props.backendSettings.betradar.systemMain, {
            page: 'liveodds_oddsHistory_wrapper',
            frames: true,
            selectedEvent: this.props.event.id,
            homeTeam: this.props.event.teamHome.name,
            awayTeam: this.props.event.teamAway.name,
            sportid: this.props.event.sportId,
        });
    };

    openLogWindow = page => {
        Utils.openWindow(this.props.backendSettings.betradar.systemMain, {
            page,
            frames: true,
            propertyNameId: 'matchid',
            propertyValue: this.props.event.id,
            search: true,
            xmlType: 'both',
            betweenDateFrom: moment
                .fromTimestamp(this.props.event.startDate)
                .minus({ hours: 1 })
                .toFormat('MM/dd/yyyy T'),
            betweenDateTo: moment
                .fromTimestamp(this.props.event.startDate)
                .plus({ hours: 5 })
                .toFormat('MM/dd/yyyy T'),
        });
    };

    onScoutXmlLogClick = () => {
        this.openLogWindow('scout_customerMessages_wrapper');
    };

    onLiveoddsXmlLogClick = () => {
        this.openLogWindow('LO_customerMessages_wrapper');
    };

    onLiveChatClick = () => {
        Chat.start(this.props.userInfo, this.props.event);
    };

    onEventInfoClick = () => {
        this.props.addDialog(dialogTypes.EVENT_INFO, {
            event: this.props.event,
        });
    };

    onExtendedEventInfoClick = () => {
        this.props.addDialog(dialogTypes.EXTENDED_EVENT_INFO, {
            event: this.props.event,
        });
    };

    onMasterInfoClick = () => {
        this.props.addDialog(dialogTypes.MASTER_INFO, {
            event: this.props.event,
        });
    };

    onLiveChannelInfoClick = () => {
        this.props.addDialog(dialogTypes.LIVE_CHANNEL_INFO, {
            event: this.props.event,
        });
    };

    onAutobookingclick = () => {
        this.props.setCurrentView(
            views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW
        );
        this.props.setTab(
            views.VIEWPORT_TYPE_AUTOBOOKING_LIST_OVERVIEW,
            tabs.FORM
        );

        const sportName = this.props.sports[this.props.event.sportId]
            ? this.props.sports[this.props.event.sportId].name
            : 'Undefined';

        this.props.setFilters({
            sportId: this.props.event.sportId,
            sportName: sportName,
            realCategoryId: this.props.event.realCategoryId,
            realCategoryName: this.props.event.realCategoryName,
            tournamentId: this.props.event.tournamentId,
            tournamentName: this.props.event.tournamentName,
            homeTeamId: this.props.event.homeTeamId,
            homeTeamName: this.props.event.homeTeam,
            awayTeamId: this.props.event.awayTeamId,
            awayTeamName: this.props.event.awayTeam,
        });
    };

    onControlOddsClick = () => {
        this.props.addDialog(dialogTypes.CONTROL_ODDS, {
            event: this.props.event,
        });
    };

    onLiveStreamClick = () => {
        Utils.openWindow(
            `/live-booking-calendar/stream/sr:match:{id}`.replace(
                '{id}',
                this.props.event.id
            ),
            {},
            {
                directories: 'no',
                titlebar: 'yes',
                scrollbars: 'no',
                resizable: 'yes',
                width: '800',
                height: '480',
            },
            '_blank'
        );
    };

    openLctPlayer = () => {
        // If bookie has access to livedataClient and match booked, this menuitem will open LDC
        return this.onLiveDataClientClick();
    };

    openLctPlayerSimple = () => {
        // Bookie has no LD booked, but LCT is booked. Show simplified LDC (video)
        const url = this.props.backendSettings.betradar.liveDataClient;
        return Utils.openWindow(
            url.replace('{type}', 'video').replace('{id}', this.props.event.id),
            {},
            {
                directories: 'no',
                titlebar: 'no',
                toolbar: 'no',
                location: 'no',
                status: 'no',
                menubar: 'no',
                scrollbars: 'no',
                resizable: 'no',
                width: '455',
                height: '390',
            },
            '_blank'
        );
    };

    onUnbookLOClick = () => {
        this.props.unbookMatch(
            this.props.currentView,
            this.props.event.uri,
            0 // no subproductid for LO
        );
    };

    onUnbookLCOClick = () => {
        this.props.unbookMatch(
            this.props.currentView,
            this.props.event.uri,
            AV_PRODUCT.ID.LCO
        );
    };

    onUnbookLCRClick = () => {
        this.props.unbookMatch(
            this.props.currentView,
            this.props.event.uri,
            AV_PRODUCT.ID.LCR
        );
    };

    onUnbookLCTClick = () => {
        this.props.unbookMatch(
            this.props.currentView,
            this.props.event.uri,
            AV_PRODUCT.ID.LCT
        );
    };

    onInspectClick = () => {
        this.props.setDefaultUri(this.props.event.uri);
        this.props.setCurrentView(views.VIEWPORT_TYPE_INSPECTOR); // set new view
    };

    onMatchConfig = () => {
        this.props.addDialog(dialogTypes.MATCH_CONFIG, {
            eventUri: this.props.event.uri,
            event: this.props.event,
        });
    };

    onShowEventObjectClick = () => {
        console.info(this.props.event); // also show object in console
        this.props.addDialog(dialogTypes.EVENT_OBJECT, {
            object: this.props.event,
        });
    };

    hideItem(item) {
        if (item.label === ADMIN_LABEL && !this.props.access.admin) {
            return true;
        }

        if (
            item.label === DEVELOPMENT_LABEL &&
            import.meta.env.MODE !== 'development' &&
            !this.props.access.developer
        ) {
            return true;
        }

        if (
            item.code &&
            this.props.event.contextMenuDetails.items.indexOf(item.code) === -1
        ) {
            return true;
        }

        if (
            item.code &&
            item.code === EXTRA_INFO &&
            !this.props.showExtraInfo &&
            !this.props.access.support
        ) {
            return true;
        }
        return false;
    }

    render() {
        if (this.props.showContextMenu === false) {
            return '';
        }
        const items = [];
        for (const item of this.getItemsData()) {
            if (this.hideItem(item)) {
                continue;
            }

            const isDisabled =
                this.props.event.contextMenuDetails.disabled.indexOf(
                    item.code
                ) !== -1;

            items.push(
                <div
                    key={`item_${item.text}`}
                    className={classnames(
                        'item icon-container',
                        (item.classes || []).join(' '),
                        {
                            disabled: isDisabled,
                        },
                        'textline-400'
                    )}
                    onClick={isDisabled ? null : item.clickHandler}
                    {...item.customProps}
                >
                    <FontIcon icon={item.icon} />
                    <div className="text">{item.text}</div>
                    <div className="label">{item.label}</div>
                </div>
            );
        }
        let contextMenuHeight = 0;
        contextMenuHeight += contextMenuItemSize.headerHeight;
        contextMenuHeight +=
            Math.ceil(items.length / 2) * contextMenuItemSize.height; // two columns so half height
        contextMenuHeight += contextMenuItemSize.footerHeight;
        const pos = calculatePosition(
            this.props.position.x,
            this.props.position.y,
            contextMenuHeight
        );

        const header = (
            <div className="context-menu-header ui-500 text-black icon-container">
                <FontIcon icon="" />
                Options
            </div>
        );

        const getCopyText = matchId => {
            if (matchId.includes('stage')) {
                return matchId;
            }
            return matchId.split(':')[2];
        };

        const footer = (
            <div
                className="context-menu-footer clipboardBtn"
                data-clipboard-text={getCopyText(this.props.event.uri)}
            >
                <div className="textline-400 text-black">
                    {`Event URI: ${getCopyText(this.props.event.uri)}`}
                </div>
                <div className="textline-300 text-gray-1100 icon-container">
                    <FontIcon icon="" />
                    (click to copy)
                </div>
            </div>
        );

        const containerEle = isMicrofrontend ? 'bweb-app' : 'portal-root';

        return ReactDOM.createPortal(
            <div>
                <DialogMask
                    onMouseDown={this.props.closeContextMenu}
                    transparent
                />
                <div
                    className="context-menu"
                    style={{ left: pos.left, top: pos.top }}
                    onMouseDown={e => e.stopPropagation()} // prevent Calendar.jsx handler from firing
                    onClick={this.props.closeContextMenu}
                    onContextMenu={e => e.stopPropagation()}
                >
                    {header}
                    <div className="context-menu-items">{items}</div>
                    {footer}
                </div>
            </div>,
            document.getElementById(containerEle)
        );
    }
}

ContextMenu.propTypes = {
    event: PropTypes.object,
    backendSettings: PropTypes.object,
    currentView: PropTypes.string,
    userInfo: PropTypes.object.isRequired,
    sports: PropTypes.object.isRequired,
    position: PropTypes.object,
    setDefaultUri: PropTypes.func.isRequired,
    setCurrentView: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    addDialog: PropTypes.func.isRequired,
    unbookMatch: PropTypes.func.isRequired,
    showExtraInfo: PropTypes.bool.isRequired,
    access: PropTypes.object.isRequired,
    showContextMenu: PropTypes.bool.isRequired,
    closeContextMenu: PropTypes.func.isRequired,
};

ContextMenu.defaultProps = {
    backendSettings: {},
    showExtraInfo: false,
};

const mapStateToProps = state => ({
    backendSettings: state.calendar.backendSettings,
    currentView: state.viewport.currentView,
    userInfo: state.calendar.auth.userInfo,
    sports: state.calendar.sports,
    showExtraInfo:
        !!state.calendar.auth.userInfo.bookmakerInfo.features.show_extra_info,
    access: state.calendar.access,
    event: state.eventContextMenu.event,
    showContextMenu: state.eventContextMenu.showContextMenu,
    position: {
        x: state.eventContextMenu.positionX,
        y: state.eventContextMenu.positionY,
    },
});

const mapDispatchToProps = {
    setDefaultUri,
    setCurrentView,
    setTab,
    setFilters,
    addDialog,
    unbookMatch: unbookEvent,
    closeContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
