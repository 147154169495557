import { PdsText } from '@podium-design-system/react-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterConstants from '../../../../../../constants/FilterConstants';
import sportIds from '../../../../../../constants/sports';
import { eventInfo } from '../../../../../../selectors/eventInfo';
import { fetchEventInfo } from '../../../../../../stores/eventInfo/actions';
import moment from '../../../../../../utils/CalendarMoment';
import BusySpinner from '../../../../../BusySpinner/BusySpinner';
import FontIcon from '../../../../../icons/FontIcon/FontIcon';
import LiveStatusIcon from '../../../../../icons/LiveStatusIcon/LiveStatusIcon';
import SportIcon from '../../../../../icons/SportIcon/SportIcon';
import AddOnsDetail from '../AddOnsDetail/AddOnsDetail';
import { buildLiveChannelGroup } from '../ContentEventInfo';
import Group from '../Group/Group';
import KeyValue from '../KeyValue/KeyValue';
import ProductDetail from '../ProductDetail/ProductDetail';
import './matchInfo.scss';

const buildOdds = (odds, loading) => {
    let response = '-';

    if (loading) {
        response = <BusySpinner size={16} margin={'0'} />;
    } else if (odds && odds.error) {
        response = <span className="error-text">{odds.error}</span>;
    } else if (odds && odds.homeodds && odds.awayodds) {
        response = [];
        response.push(
            <KeyValue key="home" keyString={'Home'} value={odds.homeodds} />
        );
        if (odds.drawodds) {
            response.push(
                <KeyValue key="draw" keyString={'Draw'} value={odds.drawodds} />
            );
        }
        response.push(
            <KeyValue key="away" keyString={'Away'} value={odds.awayodds} />
        );
    }

    return (
        <Group>
            <div className="group-header ui-700">Live Odds</div>
            <div className="odds">{response}</div>
        </Group>
    );
};

const buildConfig = (config, loading) => {
    let response = '-';

    if (loading) {
        return (
            <Group title="Checking market configuration">
                <BusySpinner size={16} margin={'0'} />
            </Group>
        );
    } else if (config && config.error) {
        response = <span className="pink">{config.error}</span>;
    } else if (config && config.assigned) {
        response = [];
        response.push(
            <div key="a">
                Template assigned to{' '}
                <span className={'tpl-lvl'}>
                    {config.assigned.effectiveType}
                </span>{' '}
                level :
            </div>
        );
        response.push(
            <div key="b">{config.assignable[config.assigned.effectiveId]}</div>
        );
    } else if (config && config.length === 0) {
        response = 'No market configured';
    }

    return <Group title="Live Odds Match Configuration">{response}</Group>;
};

const buildCoverageDetail = match => {
    const coverageDetails = [
        <div className="coverage icon-label ui-400" key="venue">
            <FontIcon icon="" />
            {match.isVenue ? 'VENUE' : 'TV'}
        </div>,
    ];

    let liveOddsCoverage = 'no';
    let scoutCoverage = 'no';

    if (match.products.lo && match.products.lo.bookingStatusId !== 0) {
        liveOddsCoverage = 'yes';

        if (match.products.lo.extended) {
            liveOddsCoverage += ' - (extended)';
        }
    }

    if (match.products.ld && match.products.ld.bookingStatusId !== 0) {
        scoutCoverage = 'yes';

        if (match.products.ld.deeper) {
            scoutCoverage += ' - (deeper)';
        } else {
            scoutCoverage += ' - (basic)';
        }
    }

    coverageDetails.push(
        <div className="side-by-side" key="coverage">
            <div className="sub-group live-odds" key="lo">
                <div className="group-header ui-600 bold">Live Odds</div>
                <div
                    className={`icon-label ui-400 icon-${
                        liveOddsCoverage === 'no' ? 'red' : 'green'
                    }`}
                >
                    <FontIcon icon={liveOddsCoverage === 'no' ? '' : ''} />
                    <div className="capitalize-first">{liveOddsCoverage}</div>
                </div>
            </div>
            <div className="sub-group scout" key="scout">
                <div className="group-header ui-600 bold">Scout</div>
                <div
                    className={`icon-label ui-400 icon-${
                        scoutCoverage === 'no' ? 'red' : 'green'
                    }`}
                >
                    <FontIcon icon={scoutCoverage === 'no' ? '' : ''} />
                    <div className="capitalize-first">{scoutCoverage}</div>
                </div>
            </div>
        </div>
    );

    return coverageDetails;
};

const buildCoverageProvider = match => {
    if (match?.products?.lo?.coverageProvider) {
        return (
            <div className="coverage-provider">
                <div className="group-header ui-600 bold">Live Odds</div>
                <div className="icon-label ui-400">
                    {match.products.lo.coverageProvider.length > 1
                        ? match.products.lo.coverageProvider.join(', ')
                        : match.products.lo.coverageProvider}
                </div>
            </div>
        );
    }
};

const buildPackageRows = (match, isDefaultSegment) => {
    const rows = [];

    const getLoType = type => {
        if (isDefaultSegment) {
            return '';
        }
        switch (type) {
            case 1:
                return 'MVP ';
            case 2:
                return 'STAR ';
            default:
                return '';
        }
    };

    if (match.products.lo && match.products.lo.isRts) {
        const loBooked = match.products.lo.isBooked;

        rows.push(
            <div
                className={`icon-label ui-400 icon-${
                    loBooked ? 'green' : 'orange'
                }`}
                key="lo-rts"
            >
                <FontIcon
                    title={loBooked ? 'Booked' : 'Not booked'}
                    icon={loBooked ? '' : ''}
                />
                Live Odds RTS
            </div>
        );
    }

    if (match.products.ld && match.products.ld.isRts) {
        const loBooked = match.products.ld.isBooked;
        rows.push(
            <div
                className={`icon-label ui-400 icon-${
                    loBooked ? 'green' : 'orange'
                }`}
                key="ld-rts"
            >
                <FontIcon
                    title={loBooked ? 'Booked' : 'Not booked'}
                    icon={loBooked ? '' : ''}
                />
                Live Data RTS
            </div>
        );
    }

    if (rows.length) {
        // Previously it returned if LO RTS. Guess the below is not relevant when RTS. Now LO AND LD RTS can be displayed
        return rows;
    }

    if (match.products.lo) {
        const loBooked = match.products.lo.isBooked;
        rows.push(
            <div
                className={`icon-label ui-400 icon-${
                    loBooked ? 'green' : 'orange'
                }`}
                key="lo"
            >
                <FontIcon
                    title={loBooked ? 'Booked' : 'Not booked'}
                    icon={loBooked ? '' : ''}
                />
                {`${getLoType(match.products.lo.vbpType)}Live Odds`}
            </div>
        );
    }

    if (match.products.ld) {
        const ldBooked = match.products.ld.isBooked;
        rows.push(
            <div
                className={`icon-label ui-400 icon-${
                    ldBooked ? 'green' : 'orange'
                }`}
                key="ld"
            >
                <FontIcon
                    title={ldBooked ? 'Booked' : 'Not booked'}
                    icon={ldBooked ? '' : ''}
                />
                {`${getLoType(match.products.ld.vbpType)}Live Data`}
            </div>
        );
    }
    return <div className="side-by-side">{rows}</div>;
};

const buildTeamsAndTournament = match => {
    let homeTeamName = match.teamHome.name;
    let awayTeamName = match.teamAway.name;

    if (match.teamHome.venue) {
        homeTeamName += ` (${match.teamHome.venue.state})`;
    }
    if (match.teamAway.venue) {
        awayTeamName += ` (${match.teamAway.venue.state})`;
    }
    if (match.ageGroup.homeTeamDob !== FilterConstants.ageGroups.Unconfirmed) {
        homeTeamName += ` (${match.ageGroup.homeTeamDob})`;
    }
    if (match.ageGroup.awayTeamDob !== FilterConstants.ageGroups.Unconfirmed) {
        awayTeamName += ` (${match.ageGroup.awayTeamDob})`;
    }
    if (
        match.ageGroup.homeTeam &&
        match.ageGroup.awayTeam &&
        match.ageGroup.match !== FilterConstants.ageGroups.Senior &&
        match.ageGroup.match !== FilterConstants.ageGroups.Unconfirmed
    ) {
        homeTeamName += ` (${match.ageGroup.homeTeam})`;
        awayTeamName += ` (${match.ageGroup.awayTeam})`;
    }

    return (
        <div key="sub-group-2">
            <div className="ui-600 bold team-home">{homeTeamName}</div>
            <div className="ui-400">vs</div>
            <div className="ui-600 bold team-away">{awayTeamName}</div>
        </div>
    );
};

const buildDetails = event => {
    let venue = '';
    let division = '';
    if (event.isNcaa && event.venue) {
        let venueName = event.venue.name;
        if (event.venue.state) {
            venueName += '(' + event.venue.state + ')';
        }
        venue = (
            <div className="icon-label ui-400 venue-holder">
                <FontIcon icon="" />
                {venueName}
            </div>
        );
    }
    if (event.isNcaa) {
        let divisionTxt = '';
        let divisionInt =
            'Division ' + event.teamHome.division > event.teamAway.division
                ? event.teamHome.division
                : event.teamAway.division;

        divisionTxt = 'Division ' + divisionInt;
        if (
            event.teamHome.division === null ||
            event.teamAway.division === null
        ) {
            divisionTxt = 'Not available';
        }

        division = (
            <div className="icon-label ui-400 division-holder">
                <FontIcon icon="" />
                {divisionTxt}
            </div>
        );
    }
    return (
        <div className="sub-group" key="sub-group-1">
            <div className="side-by-side">
                <div className="icon-label ui-400">
                    <FontIcon icon="" />
                    {event.realCategoryName}
                </div>
                <div className="icon-label ui-400 tournament-holder">
                    <FontIcon icon="" />
                    {getTournamentName(event)}
                </div>
            </div>
            <div className="side-by-side">
                {division}
                {venue}
            </div>
            <div className="court-name">
                {[
                    sportIds.TENNIS,
                    sportIds.BADMINTON,
                    sportIds.BEACH_VOLLEY,
                    sportIds.SNOOKER,
                    sportIds.TABLE_TENNIS,
                ].includes(event.sportId) && event.courtName.length
                    ? event.courtName
                    : ''}
            </div>
            <div className="extra-info">{event.extra}</div>
            {event.var && <div className="extra-info">VAR available</div>}
        </div>
    );
};

const getTournamentName = match => {
    let tournamentName = match.tournamentName;
    if (
        match.ageGroup.tournament &&
        match.ageGroup.tournament !== FilterConstants.ageGroups.Senior &&
        match.ageGroup.tournament !== FilterConstants.ageGroups.Unconfirmed
    ) {
        tournamentName += ` (${match.ageGroup.tournament})`;
    }
    return tournamentName;
};

let fetchInfoTimer = null;

class MatchInfo extends Component {
    constructor(props) {
        super(props);
        this.addOnRef = React.createRef();
        this.state = {
            addOnsIconCount: 0,
        };
    }

    componentDidMount() {
        this.setTimeoutToFetchOdds();
    }

    componentDidUpdate(prevProps) {
        if (this.props.event !== prevProps.event) {
            this.setTimeoutToFetchOdds();
            if (this.addOnRef?.current !== null) {
                const addOns =
                    this.addOnRef.current.querySelectorAll(
                        'pds-icon-button'
                    ).length;
                this.setState({ addOnsIconCount: addOns });
            }
        }
    }

    setTimeoutToFetchOdds() {
        if (fetchInfoTimer !== null) {
            clearTimeout(fetchInfoTimer);
        }
        if (!this.props.info.odds && this.props.event.products.lo) {
            fetchInfoTimer = setTimeout(
                () => this.props.fetchInfo(this.props.event.uri),
                1500
            );
        }
    }

    render() {
        const dateOfMatchMoment = moment.fromTimestamp(
            this.props.event.startDate
        );

        const time = moment.now() > dateOfMatchMoment ? 'past' : 'future';

        const liveStatusIcon = (
            <span className="livestatus">
                <LiveStatusIcon
                    liveStatus={this.props.event.status}
                    liveStatusText={this.props.event.statusText}
                    access={this.props.access}
                />
            </span>
        );

        const sportName = this.props.sports[this.props.event.sportId]
            ? this.props.sports[this.props.event.sportId].name
            : 'Undefined';

        return (
            <div className="event-info">
                <Group className="sport-info">
                    <div className="group-header icon-label ui-600">
                        <SportIcon sportId={this.props.event.sportId} />
                        {sportName}
                    </div>
                    <div className="event-uri display-200">
                        {this.props.event.uri}
                    </div>

                    <div className="group-header ui-600 space-above">
                        {dateOfMatchMoment.toFormat('DDDD')}
                    </div>
                    <div className="icon-label ui-400">
                        <FontIcon icon="" />
                        {this.props.event.statusText}
                    </div>
                    <div className="time-livestatus">
                        <div className="icon-label ui-400">
                            <FontIcon icon="" />
                            {dateOfMatchMoment.toFormat('HH:mm')}
                        </div>
                        <div
                            className={`icon-label ui-400 relative-time ${time}`}
                        >
                            {/*TODO edit icon if time has been exceeded*/}
                            <FontIcon icon={time === 'past' ? '' : ''} />
                            {dateOfMatchMoment.toRelative()}
                        </div>
                        {liveStatusIcon}
                    </div>
                </Group>
                <Group>
                    {buildTeamsAndTournament(this.props.event)}
                    {buildDetails(this.props.event)}
                </Group>
                {this.props.isNewProductsUIEnabled && (
                    <Group className="products">
                        <PdsText
                            type="textline"
                            fontSize={600}
                            fontWeight="strong"
                            color="neutral"
                            style={{ marginBottom: '10px', display: 'flex' }}
                        >
                            Products
                        </PdsText>
                        <div className="products-wrapper">
                            <ProductDetail event={this.props.event} />
                        </div>

                        {this.state.addOnsIconCount > 0 && (
                            <PdsText
                                type="textline"
                                fontSize={600}
                                fontWeight="strong"
                                color="neutral"
                                style={{
                                    marginBottom: '10px',
                                    display: 'flex',
                                }}
                            >
                                Add-ons
                            </PdsText>
                        )}

                        <div className="products-wrapper" ref={this.addOnRef}>
                            <AddOnsDetail event={this.props.event} />
                        </div>
                    </Group>
                )}
                <Group className="coverage">
                    <div className="group-header ui-700">Coverage From</div>
                    {buildCoverageDetail(this.props.event)}
                </Group>
                {this.props.isPcsCoverageEnabled && (
                    <Group className="coverage">
                        <div className="group-header ui-700">
                            Coverage Provider
                        </div>
                        {buildCoverageProvider(this.props.event)}
                    </Group>
                )}
                <Group className="packages">
                    <div className="group-header ui-700">Packages</div>
                    {buildPackageRows(
                        this.props.event,
                        (this.props.bookmakerInfo.segment.segmentId || 1) === 1
                    )}
                </Group>
                {buildLiveChannelGroup(
                    this.props.event,
                    this.props.bookmakerInfo
                )}
                {/* {buildAvailability(this.props.event)} */}
                {this.props.event.products.lo &&
                    buildOdds(this.props.info.odds, this.props.info.loading)}
                {this.props.event.products.lo &&
                    buildConfig(
                        this.props.info.config || null,
                        this.props.info.loading
                    )}
            </div>
        );
    }
}

MatchInfo.propTypes = {
    event: PropTypes.object.isRequired,
    sports: PropTypes.object.isRequired,
    bookmakerInfo: PropTypes.object.isRequired,
    fetchInfo: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    info: PropTypes.object,
    access: PropTypes.object.isRequired,
    isNewProductsUIEnabled: PropTypes.bool.isRequired,
    isPcsCoverageEnabled: PropTypes.bool.isRequired,
};

MatchInfo.defaultProps = {
    info: { odds: null },
};

const mapStateToProps = state => {
    return {
        sports: state.calendar.sports,
        info: { ...eventInfo(state) },
        bookmakerInfo: state.calendar.auth.userInfo.bookmakerInfo,
        access: state.calendar.access,
        isNewProductsUIEnabled:
            state.viewport.selectedVersion === 'new-version' ||
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .new_products_ui,
        isPcsCoverageEnabled:
            !!state.calendar.auth.userInfo.bookmakerInfo.features
                .enable_pcs_coverage,
    };
};

const mapDispatchToProps = {
    fetchInfo: fetchEventInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchInfo);
